<script lang="ts" setup>
useHead({
  titleTemplate: '%s %separator Forgd',
  templateParams: {
    separator: '·',
  },
})
</script>

<template>
  <div data-layout="core/dashboard">
    <NuxtLoadingIndicator />
    <div
      class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
    >
      <div class="flex min-h-screen relative">
        <AppMenuExpandable :expanded="true" class="z-5" />
        <div class="flex flex-col flex-grow">
          <!-- page header -->
          <div
            class="h-[80px] px-5 flex justify-between items-center border-b border-forgd-bgd-600"
          >
            <div class="flex gap-4 items-center">
              <slot name="header" />
            </div>

            <div class="flex-grow-0">
              <UiUserProjectsDropDown />
            </div>
          </div>

          <div class="m-10">
            <slot />
          </div>
          <slot name="footer">
            <AppContact class="!mt-0">
              <template #default>
              Contact us for a 1:1 consultation regarding all things related to your project’s roadmap
              </template>
              <template #cta>
                <UButton
                  size="xl"
                  color="black"
                  class="px-12 py-3"
                  to="https://calendly.com/forgd/forgd-consultation"
                  target="_blank"
                >
                  Schedule a 1:1 Consultation
                </UButton>
              </template>
            </AppContact>
          </slot>
          <AppFooter />
        </div>
      </div>
    </div>
    <UNotifications />
    <USlideovers />
  </div>
</template>
